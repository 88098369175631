import React, { useState } from "react";
import GithubSvg from "../../images/GithubSvg";
import "./Github.css";

const Github = ({ title, link, info }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="container-github-wrapper w-full flex flex-col">
      <a href={link} className="container-github-link">
        <div className="container-github flex flex-col">
          <div className="container-github-title flex items-center justify-center align-middle p-2 rounded-sm gap-4 poppins-semibold text-lg">
            <GithubSvg />
            <div>{title}</div>
          </div>
        </div>
      </a>
      <div className="container-github-info relative p-4 lg:p-6">
        {!open ? `${info.slice(0, 250).trim()}...` : <div>{info}</div>}
        {!open && (
          <span
            className="text-blue-700 hover:cursor-pointer"
            onClick={() => {
              setOpen(true);
            }}
          >
            Read More{" "}
          </span>
        )}
      </div>
    </div>
  );
};

export default Github;
