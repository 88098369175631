import "./App.css";
import "./Fonts.css";
import "./Animations.css";
import Header from "./components/Header";
import EmailNotificationSection from "./components/landing/EmailNotificationSection";
import MainPage from "./components/landing/MainPage/MainPage";
import ProcessSection from "./components/landing/ProcessSection";
import ProductSection from "./components/landing/ProductSection";
import TeamSection from "./components/landing/TeamSection";
import ContactUsModal from "./components/ContactUsModal";
import Footer from "./components/Footer";
import StorySection from "./components/landing/StorySection";
import { useState } from "react";

function App() {
  const [showModal, setShowModal] = useState(false);

  const updateModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="App-container overflow-hidden">
      <div className="App-header-container lg:w-5/6 lg:max-w-7xl lg:mx-auto relative">
        <Header updateModal={updateModal} />
      </div>
      <MainPage />
      <div className="App gap-y-7 lg:w-5/6 lg:max-w-7xl lg:mx-auto relative">
        <ContactUsModal show={showModal} updateModal={updateModal} />
        <StorySection />
        <ProcessSection />
        <ProductSection />
        <TeamSection />
        <EmailNotificationSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;
