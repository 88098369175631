import React, { useRef, useState, useEffect } from "react";
import "./Medium.css";

const Medium = ({ title, link, image }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const mediumRef = useRef();
  const imageRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (mediumRef.current) {
      const rect = mediumRef.current.getBoundingClientRect();
      if (
        mousePosition.x >= rect.left &&
        mousePosition.x <= rect.right &&
        mousePosition.y <= rect.bottom &&
        mousePosition.y >= rect.top
      ) {
        imageRef.current.classList.add("hovered-image");
        titleRef.current.classList.add("hovered-title");
      } else {
        imageRef.current.classList.remove("hovered-image");
        titleRef.current.classList.remove("hovered-title");
      }
    }
  }, [mousePosition]);
  return (
    <div className="container-medium pt-5 relative " ref={mediumRef}>
      <div className="container-image relative overflow-hidden w-full rounded-xl">
        <div
          className="medium-title text-1xl lg:text-sm px-2 z-50 w-full h-16 lg:h-24 relative lg:-bottom-8 items-center justify-center lg:absolute lg:pl-4 lg:pb-8 lg:bg-white lg:bg-opacity-90 hidden lg:flex"
          ref={titleRef}
        >
          {title}
        </div>
        <a href={link}>
          <img
            className="medium-image relative rounded-md"
            alt="thumbnail"
            src={image}
            ref={imageRef}
          ></img>{" "}
        </a>
        <div className="medium-title-mobile px-1 text-xs flex items-center justify-center lg:absolute lg:pl-4 pt-1 lg:bg-white lg:bg-opacity-90 lg:hidden">
          {title}
        </div>
      </div>
    </div>
  );
};

export default Medium;
