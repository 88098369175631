import React, { useState } from "react";
import Header from "../Header";
import ContactUsModal from "../ContactUsModal";
import ResearchBody from "./ResearchBody";
import Footer from "../Footer";

const Research = () => {
  const [showModal, setShowModal] = useState(false);

  const updateModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="App-container">
      <div className="App-header-container lg:w-5/6 lg:max-w-7xl lg:mx-auto relative">
        <Header updateModal={updateModal} />
      </div>
      <ResearchBody />
      <div className="App gap-y-7 lg:w-5/6 lg:max-w-7xl lg:mx-auto relative">
        <ContactUsModal show={showModal} updateModal={updateModal} />
      </div>
      <Footer />
    </div>
  );
};

export default Research;
