import React from "react";
import Button from "../Button";

const EmailNotificationSection = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent the form from submitting the traditional way
    setResult("Submitting...");
    const emailValue = event.target.email.value; // Change to get value from the input

    try {
      const response = await fetch(
        "https://73fnqcauik.execute-api.us-west-2.amazonaws.com/prod/mailing/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({ email: emailValue }),
        }
      );

      if (response.ok) {
        setResult("Subscription successful!");
      } else {
        setResult("Subscription failed: ");
      }
    } catch (error) {
      setResult("An error occurred: " + error.message);
    }
  };
  return (
    <div className="email-notification-section-container mt-24 w-full self-center flex flex-col px-12 lg:px-0 lg:w-2/3 ">
      <div className="email-notification-header text-3.2xl poppins-semibold">
        Subscribe to join the waitlist
      </div>
      <div className="email-notification-body-container w-11/12 lg:w-3/5 self-center">
        <form
          className="email-notification-section-input mt-6 flex flex-col"
          onSubmit={onSubmit}
        >
          <input type="email" name="email" required placeholder="your email" />
          {result !== "Subscription successful!" && (
            <button className="w-max self-center" type="submit">
              <Button text="Subscribe" type="subscribe" onclick={() => {}} />
            </button>
          )}
        </form>
        <span className="mt-4">{result}</span>
      </div>
    </div>
  );
};

export default EmailNotificationSection;
