import React from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { ResearchMainArticle } from "./ResearchMainArticle";
import ResearchGithub from "./ResearchGithub";

const ResearchBody = () => {
  return (
    <div className="container-main lg:relative h-max lg:w-3/4 lg:max-w-7xl lg:mx-auto lg:mb-0 flex flex-col pt-32 px-4 lg:px-0">
      <div className="w-max inline-flex items-center text-sm poppins-regular">
        <a href="/" className="hover:underline">
          Home
        </a>
        <span className="flex items-center">
          {<ChevronRightRoundedIcon />} Research
        </span>
      </div>
      <div className="research-body-title text-5xl poppins-medium mt-8">
        Research
      </div>
      <div className="research-body-description text-mg poppins-light mt-4 mb-6">
        Read more about our research on autonomous driving, AI models, and
        algorithms
      </div>
      <ResearchMainArticle />
      <div className="px-2">
        <div className="bg-black opacity-10 h-px mt-16 w-full"></div>
      </div>
      <div className="research-body-read-more w-full text-left mt-16 flex flex-col px-2 pb-4">
        <div className="poppins-semibold text-3xl">Read more</div>
        <div className="poppins-regular text-green-700">
          The models behind our vision
        </div>
      </div>
      <ResearchGithub />
    </div>
  );
};

export default ResearchBody;
