import React from "react";
import Github from "./Github";

const ResearchGithub = () => {
  return (
    <div className="container-github-showcase py-2 px-2 grid grid-cols-1 lg:flex max-w-screen-sm gap-y-8 lg:max-w-screen-2xl lg:gap-x-4">
      <Github
        title="Gaussian Mixture MCMC"
        link="https://github.com/whatheheckisthis/Save-A-Life/tree/Gaussian-Mixture-Model"
        info="Integrating Mask R-CNN with our HMM framework significantly advances our approach to road safety. By accurately detecting and segmenting objects in real-time, our system provides actionable insights to improve driver assistance technologies. This demonstration showcases the potential of our technology to enhance road safety and the driving experience."
      />
      <Github
        title="HMMs for Autonomous Driving"
        link="https://github.com/whatheheckisthis/Save-A-Life/tree/HMM-Lane-Detection"
        info="
This algorithm introduces HMM terminology through a basic example, applies Bayes' rule to identify the most likely hidden state from an observation, and demonstrates the Forward and Backward Algorithms to compute observation sequence probabilities. It explains the benefits of Dynamic Programming, and implements the Viterbi Decoding Algorithm to find the most likely hidden state sequence and the Baum-Welch Algorithm to determine the most likely HMM parameters."
      />
    </div>
  );
};

export default ResearchGithub;
