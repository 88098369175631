import React from "react";
import SalPic from "../images/SalPic.jsx";
import Button from "./Button";
import BurgerMenu from "./BurgerMenu";
import "./Header.css";

const Header = ({ updateModal }) => {
  const links = [
    { href: "/#OurStory", label: "Our Story" },
    { href: "/#Process", label: "Process" },
    { href: "/#Product", label: "Product" },
    { href: "/#Team", label: "Team" },
    { href: "/Research", label: "Research" },
    {
      label: (
        <Button type="contactUs" text="Contact Us" onclick={updateModal} />
      ),
    },
  ];
  return (
    <div className="App-header p-4 lg:p-0 w-full absolute flex z-30">
      <div className="logo ml-4 flex text-black gap-x-1 lg:gap-x-2 items-center">
        <a href="/" className="flex items-center gap-x-2">
          <SalPic className="salpic w-10 h-10 lg:w-20 lg:h-20" />
          <span className="poppins-medium text-lg lg:text-2xl">
            Save a Life
          </span>
        </a>
      </div>
      <div className="container-links grow text-black text-base pr-10 transition-colors content-evenly align-middle items-center justify-end hidden sm:flex">
        {links.map((link, index) => (
          <a className="ml-10" href={link.href} key={index}>
            <div
              className={
                (index !== links.length - 1 ? "header-link" : "") +
                "  font-header-black hover:text-green-700"
              }
            >
              {link.label}
            </div>
          </a>
        ))}
      </div>
      <div className="container-burger-menu flex self-center mr-2 sm:hidden">
        <BurgerMenu links={links} />
      </div>
    </div>
  );
};

export default Header;
