import React from "react";
import Medium from "./Medium";
import MainMedium from "./MainMedium";
import CudaSegmentation from "../../images/cudaSegmentation.png";

export const ResearchMainArticle = () => {
  return (
    <div className="research-main-container-mediums">
      <MainMedium
        title="Everything you need to know about Self-Driving Cars in <30 minutes"
        link="https://srianumakonda.medium.com/everything-you-need-to-know-about-self-driving-in-30-minutes-b38d68bd3427"
        image="https://miro.medium.com/v2/resize:fit:3200/format:webp/0*U1dnPMkRtv9y5Pk4.png"
      />
      <div className="container-medium-articles max-w-screen-sm w-11/12 mx-auto -mt-8 lg:w-full lg:-mt-1 lg:max-w-screen-2xl lg:gap-0 gap-x-2 lg:gap-x-4 grid grid-cols-3 overflow-hidden ">
        <Medium
          title="DataGAN: Leveraging Synthetic Data for Self-Driving Vehicles"
          link="https://srianumakonda.medium.com/datagan-leveraging-synthetic-data-for-self-driving-vehicles-6e629968a567"
          image="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ATRS2A5S1Z0xAc-c6p7bkQ.png"
        />
        <Medium
          title="Creating Autonomous Vehicles with Deep-Q Networks"
          link="https://srianumakonda.medium.com/everything-you-need-to-know-about-reinforcement-learning-in-80minutes-4cd5a365e340"
          image="https://srianumakonda.com/images/rl.gif"
        />
        <Medium
          title="CUDA-Optimized Semantic Segmentation for Autonomous Vehicles"
          link="https://github.com/srianumakonda/Semantic-Segmentation-CUDA"
          image={CudaSegmentation}
        />
      </div>
    </div>
  );
};
