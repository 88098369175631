import React, { useRef, useState, useEffect } from "react";
import "./MainMedium.css";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const MainMedium = ({ title, link, image }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const mediumRef = useRef();
  const imageRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (mediumRef.current) {
      const rect = mediumRef.current.getBoundingClientRect();
      const rectTitle = titleRef.current.getBoundingClientRect();
      if (
        mousePosition.x >= rect.left &&
        mousePosition.x <= rect.right &&
        mousePosition.y <= rect.bottom &&
        mousePosition.y >= rect.top &&
        !(
          mousePosition.x >= rectTitle.left &&
          mousePosition.x <= rectTitle.right &&
          mousePosition.y <= rectTitle.bottom &&
          mousePosition.y >= rectTitle.top
        )
      ) {
        imageRef.current.classList.add("hovered-image");
      } else {
        imageRef.current.classList.remove("hovered-image");
      }
    }
  }, [mousePosition]);
  return (
    <div className="container-medium-main pt-5 relative lg:flex">
      <div
        className="container-image rounded-xl relative overflow-hidden w-full"
        ref={mediumRef}
      >
        <a href={link}>
          <img
            className="medium-image relative"
            alt="thumbnail"
            src={image}
            ref={imageRef}
          ></img>
        </a>
      </div>
      <div
        className="medium-title-mobile bg-white rounded-xl lg:rounded-lg poppins-regular -top-8 px-4 py-16 w-11/12 z-50 h-16 lg:px-12 lg:-mr-16 lg:top-8 lg:right-16 lg:h-full lg:w-2/5 lg:py-20 relative items-left justify-center mx-auto shadow-lg flex flex-col text-center lg:text-left"
        ref={titleRef}
      >
        <span className="text-sm poppins-semibold text-green-600">
          GET STARTED
        </span>
        <span className="text-1xl lg:text-2xl poppins-semibold text-blue-700 lg:text-gray-800 hover:text-blue-700">
          <a href={link}> {title}</a>
        </span>
        <span className="align-middle text-green-600 text-sm hidden mt-8 lg:flex">
          <a href={link}>
            Read more <ChevronRightRoundedIcon style={{ fontSize: 20 }} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default MainMedium;
